import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

import { useHomeTabs } from ':dapp/pages/home/hooks/useHomeTabs';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';

export function useActiveHomeTab() {
  const router = useRouter();
  const homeTabs = useHomeTabs();

  const handleTabChange = useCallback(
    (tab: string) => {
      router.push(`${RoutesEnum.HOME_ROOT}/${tab}`, undefined, { scroll: false });
    },
    [router],
  );

  return useMemo(() => {
    const tabType = router.pathname.split('/').filter(Boolean)[1];

    return {
      activeTab: tabType || homeTabs[0]?.id,
      onTabChange: handleTabChange,
    };
  }, [handleTabChange, router, homeTabs]);
}

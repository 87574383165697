import { SpacingProps } from '@cbhq/cds-common';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import { TextBody, TextLabel1, TextTitle1 } from '@cbhq/cds-web/typography';

type TextHeaderProps = SpacingProps & {
  title: string;
  tag?: string;
  subtitle?: string;
  mobileContent?: React.ReactNode;
  id?: string;
};
export function HomeSectionTextHeader({
  title,
  tag,
  subtitle,
  mobileContent,
  id,
  ...spacing
}: TextHeaderProps) {
  if (!subtitle) {
    return (
      <HStack gap={2} alignItems="center">
        <TextTitle1 as="h2" {...spacing} id={id}>
          {title}
        </TextTitle1>
        {tag && (
          <Tag height={26} colorScheme="gray">
            <TextLabel1 as="span">{tag}</TextLabel1>
          </Tag>
        )}
      </HStack>
    );
  }

  return (
    <VStack {...spacing} justifyContent="center" id={id}>
      <HStack gap={2} alignItems="center">
        <TextTitle1 as="h2" spacingBottom={1} key="section_title_h2">
          {title}
        </TextTitle1>
        {tag && (
          <Tag height={26} colorScheme="gray">
            <TextLabel1 as="span">{tag}</TextLabel1>
          </Tag>
        )}
      </HStack>
      <TextBody as="p" color="foregroundMuted" spacingHorizontal={0}>
        {subtitle}
      </TextBody>
      {mobileContent}
    </VStack>
  );
}

import { useMemo } from 'react';
import { SpacingProps } from '@cbhq/cds-common';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Group, HStack } from '@cbhq/cds-web/layout';

import { HomeSectionTextHeader } from './HomeSectionTextHeader';

type TextHeaderProps = SpacingProps & {
  title: string;
  tag?: string;
  subtitle?: string;
  mobileContent?: React.ReactNode;
  id?: string;
};
type Props = SpacingProps &
  TextHeaderProps & {
    rightContent?: React.ReactNode;
    overrideMobile?: boolean;
    transpose?: boolean;
  };

/**
 * @param overrideMobile forces horizontal layout for rightContent on mobile width
 * @param transpose forces horizontal layout with rightContent and subtitle to be below (eg. earn badges for mobile)
 */
export function HomeSectionHeader({
  overrideMobile = false,
  transpose = false,
  rightContent,
  title,
  tag,
  subtitle,
  id,
  ...spacing
}: Props) {
  if (!rightContent) {
    <HomeSectionTextHeader {...spacing} title={title} tag={tag} subtitle={subtitle} id={id} />;
  }

  const { isPhone } = useBreakpoints();
  const isMobile = isPhone && !overrideMobile;
  const flexDir = isMobile || transpose ? ('column' as const) : ('row' as const);
  const alignDir = isMobile ? ('flex-start' as const) : ('center' as const);

  // text in mobile mode is align center so easiest way to
  // line it up is just to pass it down
  const mobileContent = isMobile ? rightContent : null;

  return useMemo(
    () => (
      <Box
        flexDirection={flexDir}
        justifyContent="space-between"
        alignItems={alignDir}
        {...spacing}
        id={id}
      >
        {transpose ? (
          <Group gap={2} width="100%">
            <HStack justifyContent="space-between" width="100%">
              <HomeSectionTextHeader title={title} tag={tag} mobileContent={mobileContent} />
              {!isMobile ? rightContent : null}
            </HStack>
            <HomeSectionTextHeader title="" subtitle={subtitle} mobileContent={mobileContent} />
          </Group>
        ) : (
          <>
            <HomeSectionTextHeader
              title={title}
              tag={tag}
              subtitle={subtitle}
              mobileContent={mobileContent}
            />
            {!isMobile ? rightContent : null}
          </>
        )}
      </Box>
    ),
    [
      flexDir,
      alignDir,
      spacing,
      id,
      transpose,
      title,
      tag,
      subtitle,
      mobileContent,
      isMobile,
      rightContent,
    ],
  );
}

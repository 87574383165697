import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateOCSOptIn } from 'cb-wallet-data/hooks/Gamification/useMutateOCSOptIn';
import { useToggler } from '@cbhq/cds-common';
import { Group } from '@cbhq/cds-web/layout';

import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';

import { HomeWalletSelectorModal } from './HomeWalletSelectorModal';

type OptInWrapperProps = {
  children: ReactNode;
};

export function OptInWrapper({ children }: OptInWrapperProps) {
  const { account, setPreferredAccount, accountsWithBalances, hasPreferredWallet } =
    usePreferredAccount({
      blockchainSymbol: 'ETH',
      feature: WalletPreferenceFeature.OCS,
    });

  const [isSelectorModal, { toggleOff: closeSelectorModal, toggleOn: openSelectorModal }] =
    useToggler(false);

  const queryClient = useQueryClient();
  const { mutate } = useMutateOCSOptIn();

  const setAddress = useCallback(
    (accountToUse: string) => {
      const matchingAccounts = accountsWithBalances.filter(
        (a) => a.primaryAddressForDapp === accountToUse,
      );
      if (matchingAccounts.length) {
        const acct = matchingAccounts[0];
        setPreferredAccount(acct);
        mutate(
          { walletAddress: acct.primaryAddressForDapp },
          {
            onSuccess: () => {
              /* istanbul ignore next */
              setTimeout(() => {
                queryClient.invalidateQueries({
                  queryKey: ['game_userProfileStateV2'],
                });
              }, 2000);
            },
          },
        );
      }
    },
    // setPreferredAccount causes infinite loop
    // eslint-disable-next-line wallet/exhaustive-deps
    [accountsWithBalances, mutate, queryClient],
  );

  const hasPreferredOcsWallet = useMemo(() => {
    return hasPreferredWallet(WalletPreferenceFeature.OCS);
  }, [hasPreferredWallet]);

  const walletSelectorModalDismissed = useCallback(() => {
    closeSelectorModal();
    if (!hasPreferredOcsWallet && account && accountsWithBalances.length) {
      setAddress(account.primaryAddressForDapp);
    }
  }, [closeSelectorModal, hasPreferredOcsWallet, account, accountsWithBalances.length, setAddress]);

  const walletSelectorModal = useMemo(() => {
    if (!isSelectorModal) return null;
    return (
      <HomeWalletSelectorModal handleClose={walletSelectorModalDismissed} setAddress={setAddress} />
    );
  }, [walletSelectorModalDismissed, setAddress, isSelectorModal]);

  useEffect(
    function modalFlow() {
      if (accountsWithBalances.length > 1 && !isSelectorModal && !hasPreferredOcsWallet) {
        openSelectorModal();
      } else if (account && accountsWithBalances.length === 1 && !hasPreferredOcsWallet) {
        setAddress(account.primaryAddressForDapp);
      }
    },
    [
      account,
      accountsWithBalances.length,
      hasPreferredOcsWallet,
      openSelectorModal,
      isSelectorModal,
      setAddress,
    ],
  );

  return (
    <Group testID="optin-wrapper" width="100%" alignItems="center">
      {walletSelectorModal}
      {children}
    </Group>
  );
}

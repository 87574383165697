import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { CardWithImage } from ':dapp/components/Card/CardWithImage';
import { ContentComponent, OCSChallengeCard } from ':dapp/pages/home/types/homeTypes';
import { useExperienceModalContext } from ':dapp/providers/ExperienceModalProvider';
import { useShareModalContext } from ':dapp/providers/ShareModalProvider';

export type ExperienceCardProps = {
  card: ContentComponent;
};

const messages = defineMessages({
  ocsOnchainExperiencesCtaPoints: {
    defaultMessage: '(+{value} points)',
    description:
      'indicator to the user for how many points they will earn by completing this challenge',
  },
});

export function ExperienceCard({ card }: ExperienceCardProps) {
  const { openExperienceModal } = useExperienceModalContext();
  const { openShareModal } = useShareModalContext();
  const { formatMessage } = useIntl();

  const content = card.ocsChallengeCard;
  const image = useMemo(() => ({ src: content.imageUrl }), [content]);
  const text = `${content.ctaText}${
    content.points > 0
      ? ` ${formatMessage(messages.ocsOnchainExperiencesCtaPoints, { value: content.points })}`
      : ''
  }`;

  const action = useMemo(
    () => ({
      text,
      url: content.targetUrl,
    }),
    [content.targetUrl, text],
  );
  const challengeCard = useMemo(() => ({ ...content, id: card.id }), [content, card]);

  const handleClick = useCallback(
    (challenge: OCSChallengeCard) => () => {
      openExperienceModal(challenge);
    },
    [openExperienceModal],
  );

  const handleShareClick = useCallback(
    (challenge: OCSChallengeCard) => (event: React.MouseEvent) => {
      event.stopPropagation();
      openShareModal(challenge);
    },
    [openShareModal],
  );
  return (
    <CardWithImage
      title={content.title}
      label={content.creatorName}
      hasCompleted={content.hasCompleted === 2}
      image={image}
      isGradient
      action={action}
      shareClick={handleShareClick(challengeCard)}
      onClick={handleClick(challengeCard)}
    />
  );
}

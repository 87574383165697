import { ErrorBoundary } from 'wallet-cds-web/components/ErrorBoundary';
import { GenericErrorPage } from 'wallet-cds-web/components/GenericErrorPage';
import { ResponsiveProps } from '@cbhq/cds-common';
import { zIndex } from '@cbhq/cds-common/tokens/zIndex';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { paletteValueToCssVar } from '@cbhq/cds-web/utils/palette';

import { HomeTabs } from ':dapp/pages/home/components/HomeTabs';

import { OptInWrapper } from './OptInWrapper';

const responsiveWrapperSpacing: ResponsiveProps = {
  phone: {
    gap: 3,
  },
  tablet: {
    gap: 3,
  },
  desktop: {
    gap: 3,
  },
};

const responsiveViewSpacing: ResponsiveProps = {
  phone: {
    spacingHorizontal: 3,
  },
  tablet: {
    spacingHorizontal: 4,
  },
  desktop: {
    spacingHorizontal: 4,
  },
};
const responsiveTabSpacing: ResponsiveProps = {
  phone: {
    spacingHorizontal: 3,
  },
  tablet: {
    spacingHorizontal: 4,
  },
  desktop: {
    spacingHorizontal: 4,
  },
};

type AssetsPageWrapperProps = {
  children: React.ReactNode;
};

export function HomePageWrapper({ children }: AssetsPageWrapperProps) {
  return (
    <VStack width="100%" alignItems="center">
      <OptInWrapper>
        <VStack responsiveConfig={responsiveWrapperSpacing} width="100%" maxWidth={1440}>
          <HStack
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
            top="80px"
            zIndex={zIndex.navigation}
            responsiveConfig={responsiveTabSpacing}
            dangerouslySetBackground={paletteValueToCssVar('gray0')}
            testID="home-tabs-container"
            borderedBottom
          >
            <HomeTabs />
          </HStack>
          <ErrorBoundary fallback={<GenericErrorPage />} context="dapp_error">
            <VStack spacingBottom={2} responsiveConfig={responsiveViewSpacing}>
              {children}
            </VStack>
          </ErrorBoundary>
        </VStack>
      </OptInWrapper>
    </VStack>
  );
}

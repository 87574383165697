import { defineMessages, useIntl } from 'react-intl';
import Image from 'next/image';
import { Group, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

const messages = defineMessages({
  homeOutageTitle: {
    defaultMessage: 'Oops! Home is currently unavailble',
    description: 'Home outage title',
  },
  homeOutageSubtitle: {
    defaultMessage: 'We’re working on it. Please try again later.',
    description: 'Home outage subtitle',
  },
  homeAltMessage: {
    defaultMessage: 'Home not available',
    description: 'Alt message for home outage image',
  },
});

export function HomeOutage() {
  const { formatMessage } = useIntl();

  return (
    <VStack width="100%" testID="home-outage">
      <VStack alignItems="center" spacingVertical={4} gap={3}>
        <Image
          alt={formatMessage(messages.homeAltMessage)}
          src="https://go.wallet.coinbase.com/static/chickenFishSystemError.png"
          width={240}
          height={240}
        />
        <Group gap={1} alignItems="center">
          <TextTitle3 as="div" align="center">
            {formatMessage(messages.homeOutageTitle)}
          </TextTitle3>
          <TextBody as="p" color="foregroundMuted" spacingHorizontal={0}>
            {formatMessage(messages.homeOutageSubtitle)}
          </TextBody>
        </Group>
      </VStack>
    </VStack>
  );
}

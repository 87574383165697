import { TabNavigation } from '@cbhq/cds-web/tabs/TabNavigation';

import { useActiveHomeTab } from ':dapp/pages/home/hooks/useActiveHomeTab';
import { useHomeTabs } from ':dapp/pages/home/hooks/useHomeTabs';

export const testID = 'home-tabs';

export function HomeTabs() {
  const tabs = useHomeTabs();
  const { activeTab, onTabChange } = useActiveHomeTab();

  return (
    <div>
      {tabs && tabs.length > 0 && (
        <TabNavigation testID={testID} tabs={tabs} value={activeTab} onChange={onTabChange} />
      )}
    </div>
  );
}

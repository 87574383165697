import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';

export const BASE_TEST_ID = 'home-tabs';

const messages = defineMessages({
  trending: {
    defaultMessage: 'Trending',
    description: 'Label for Trending in the Home page',
  },
  home: {
    defaultMessage: 'Explore',
    description: 'Label for Explore in the Home page',
  },
});

export enum HomeTabKey {
  TRENDING = 'trending',
  EXPLORE = 'explore',
}

export function useHomeTabs() {
  const { exploreApiIssue } = usePageOutageContext(); // this is controlled at runtime based on api success, etc
  const { formatMessage } = useIntl();

  return useMemo(() => {
    const visibleTabs: { id: HomeTabKey; label: string; testID: string }[] = [];

    if (exploreApiIssue) {
      return visibleTabs;
    }

    visibleTabs.push({
      id: HomeTabKey.TRENDING,
      label: formatMessage(messages.trending),
      testID: `${BASE_TEST_ID}-trending`,
    });

    visibleTabs.push({
      id: HomeTabKey.EXPLORE,
      label: formatMessage(messages.home),
      testID: `${BASE_TEST_ID}-home`,
    });

    return visibleTabs;
  }, [exploreApiIssue, formatMessage]);
}
